<template>
  <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
    <!-- Form -->
    <b-form class="p-2" @submit.prevent v-if="record">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Home Office">
            <b-form-checkbox class="custom-control-success mt-1" name="check-button" switch v-model="record.homeoffice">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Presencial">
            <b-form-checkbox class="custom-control-success mt-1" name="check-button" switch v-model="record.presential">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Somente Transmissão">
            <b-form-checkbox class="custom-control-success mt-1" name="check-button" switch v-model="record.streaming">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Ativo">
            <b-form-checkbox class="custom-control-success mt-1" name="check-button" switch v-model="record.active">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Capacidade">
            <div class="form-label-group">
              <b-form-input v-model="record.capacity" placeholder="Capacidade" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <button--c :variant="'info'" :iconsize="'20'" :icon="null" :permission="'permission.studiogroup.edit'"
        :title="'Salvar'" @clicked="validateSave()" class="mr-1" />
    </b-form>
    <!-- INICIO MODAL AGENDAMENTO -->
    <b-modal ref="modal-appointments-list" title="Agendamento(s) ativo(s)" hide-footer no-close-on-backdrop scrollable>
      <b-row style="min-height: 250px; margin-top: 10px">
        <b-col cols="12">
          <div style="color: red" class="tooltips-itens">
            Não é possível inativar esse estúdio com agendamentos ativos
          </div>
          <br />
        </b-col>
        <b-col cols="12">
          <b-list-group v-for="(element, id) in currentAppointments" :key="id">
            <b-list-group-item class="p-50 mb-1">
              <div class="tooltips-itens" v-if="element.id">
                ID do Agendamento:
                <b>
                  <b-link target="_blank" class="underline" :to="`/pedagogic/calendar?q=${element.id}`">#{{ element.id
                    }}</b-link>
                </b>
              </div>
              <div class="tooltips-itens">
                Data do agendamento:
                <b> {{ formatDate(element.start) }} </b>
              </div>

            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 8px; margin-top: 20px">
        <b-col style="text-align: right">
          <b-button @click="closeModal">Fechar</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- FIM MODAL AGENDAMENTO -->
  </b-overlay>

</template>
<script>
import useAppConfig from "@core/app-config/useAppConfig";
import _studiosService from "@/services/studios-service";
import _appointmentService from "@/services/appointment-service";
import {
  BListGroupItem,
  BListGroup,
  BLink
} from "bootstrap-vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BListGroupItem,
    BListGroup,
    BLink
  },
  props: {
    dto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      debounceMilliseconds: 300,
      timeout: null,
      isloading: false,
      record: {},
      currentAppointments: [],
    };
  },
  created() {
    this.getInitials();
    this.getRecord();
  },
  methods: {
    getInitials() {
      this.record = this.dto;
      this.record.duration = "";
    },
    getRecord() {
      if (this.dto.id > 0) {
        this.isloading = true;
        _studiosService
          .find(this.dto.id)
          .then((res) => {
            this.record = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    closeModal() {
      this.$refs["modal-appointments-list"].hide();
    },
    validateSave() {
      this.isloading = true;
      this.currentAppointments = [];
      if (!this.record.active) {
        _appointmentService.getAppointmentsByStudio(this.record.id)
          .then((res) => {
            if (res.content.length > 0) {
              this.currentAppointments = res.content;
              this.$refs["modal-appointments-list"].show();
              this.isloading = true;
              return;
            } else {
              this.save();
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      } else {
        this.save();
      }
    },
    save() {
      const payload = { data: { ...this.record } };
      _studiosService
        .update(payload)
        .then(() => {
          this.$utils.toast("Sucesso", "Atualizado com sucesso.");
          this.$emit("result", { status: "ok" });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));

    },
    formatDate(dateTime) {
      const [date, time] = dateTime.split("T")
      return `${date.split("-").reverse().join("/")} às ${time.slice(0, 5).replace(':', 'h')}`;
    },
  },
};
</script>